import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;

class FrameService extends IService{
    constructor(http){
        super(http);
    }

    async upload(data){
        try{
            const response = await this.http.post(`${server_base}/api/files/upload`, data);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async categories(){
        try{
            const response = await this.http.get(`${server_base}/api/frames/categories`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async allFrames(){
        try{
            const response = await this.http.get(`${server_base}/api/frames`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async frames(type){
        try{
            const response = await this.http.get(`${server_base}/api/frames/${type}`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async deleteFrame(id) {
        try{
            const response = await this.http.delete(`${server_base}/api/frames`, {id});
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async deleteFrames(type) {
        try{
            const response = await this.http.delete(`${server_base}/api/frames/${type}`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async uploadFrames(type, data){
        try{
            const response = await this.http.post(`${server_base}/api/frames/${type}`, { frames:data });
            return response.data;
        }catch (e) {
            return null;
        }

    }
}

export default FrameService;
