import FrameService from '@/services/frame.service.js';

const service = new FrameService();

export default {
  name: 'FrameUploader',

  props: {
    uploadType: String,
    defaultType: String,
    category: {type: String, default: ''},
    onChange: Function
  },

  data() {
    return {
      types: [],
      type: this.defaultType,
      loadedImages: []
    }
  },

  watch: {
    type() {
      if (this.onChange) this.onChange(this.loadedImages, this.type);
    }
  },

  async mounted() {
    const res = await service.categories();
    if (res && !res.error) {
      this.types = res.data;
    }
  },

  methods: {
    handleChange(e) {
      for (let image of e.target.files) {
        let img = {
          image: image,
          data: null
        };
        const reader = new FileReader();

        const $data = new FormData();
        $data.append('title', 'Upload Photo');
        $data.append('file', image);

        reader.onloadend = async () => {

          const res = await service.upload($data);
          if (res && !res.error) {
            img.imageId = res.data._id;
            img.imageName = res.data.Name;
            img.data = reader.result;

            const index = this.loadedImages.find(p => p.imageName === img.imageName);

            if (!index) {
              this.loadedImages.push(img);
              if (this.onChange) this.onChange(this.loadedImages, this.type);
            }
          }

        };

        reader.readAsDataURL(image);
      }
    },

    deleteImage(image) {
      const index = this.loadedImages.findIndex(i => i.imageId === image.imageId);
      if (index > -1) {
        this.loadedImages.splice(index, 1);
        if (this.onChange) this.onChange(this.loadedImages, this.type);
      }
    }
  }
}